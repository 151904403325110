/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import WOW from "wow.js"

import Header from "./header"
import Footer from "./footer"
import ScrollToTop from "./scrollToTop"
import CookieConsent from "./cookieConsent"
import Preloader from "./preloader"


import "../assets/css/vendor/bootstrap.min.css"
import "../assets/css/font-awesome.min.css"
import "../assets/css/vendor/nice-select.css"
import "../assets/css/vendor/animate.min.css"
import "../assets/css/vendor/lightcase.css"
import "../assets/css/vendor/slick.css"
import "../assets/sass/style.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      new WOW().init()
    }
  }, [])

  return (
    <>
      <Preloader />
      <ScrollToTop />
      <CookieConsent />
      <main className="page-wrapper">
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        {children}
        <Footer />
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
